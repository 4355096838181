import React from 'react';

/**
 * VideoPlayerContext was created as a stepping stone for converting Liveblog page class component to functional component.
 * Ideally new properties would not be added to this context moving forward.
 */
export const VideoPlayerContext = React.createContext({
  setStickyVideoFlag: () => {},
  isLiveBlog: false,
});

const akaasKeys = [
  'akaas_NBCNews',
  'akaas_MSNBC',
  'akaas_TODAY',
  'akaas_TELEMUNDO',
];

export function getCookie(name) {
  try {
    const cookies = document?.cookie?.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`) ?? null;
    return cookies ? cookies.pop() : null;
  } catch (e) {
    return null;
  }
}

const getRvVal = (cookie) => {
  const rvMatch = cookie && cookie.match('(^|~)\\s*rv\\s*=\\s*([^~]+)');
  return rvMatch?.[2];
};

const isWithinRange = (min, max, val) => min <= val && max >= val;

// takes an array of tuples of options to percentages: [['a', 2], ['b', 2], ['c', 96]]
// returns 'a' with probability of 2%, 'b' with probability of 2%, c with probablity of 96%
// we use an array rather than an object because it's ordered
// Option values do not need to add up to 100. Function will return null if a user has a
// rv cookie outside of the given ranges.
export const cookieOptionDecider = (options) => {
  let cookieName;

  for (let i = 0; i < akaasKeys.length; i += 1) {
    const akaasKey = akaasKeys[i];
    cookieName = getCookie(akaasKey);

    if (cookieName) break;
  }

  const cookieVal = parseInt(getRvVal(cookieName), 10);
  if (!cookieVal) return null;

  let curr = 0;

  const [testingOption] = options.find(([, percent]) => {
    const inRange = isWithinRange(curr, curr + percent, cookieVal);
    curr += percent;

    return inRange;
  }) || [];

  return testingOption ?? null;
};

// Helpers for BENTO-16393 to format the value of the featureFlag cookie.
export const explodeFeatureFlagValue = (val) => {
  const ret = {};
  val.split('~').forEach((v) => {
    const [key, value] = v.split('=');
    ret[key] = value;
  });
  return ret;
};

export const implodeFeatureFlagValue = (val) => {
  const ret = [];
  Object.keys(val).forEach((v) => {
    ret.push(`${v}=${val[v]}`);
  });
  return ret.join('~');
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/IconLoader';
// eslint-disable-next-line import/no-unresolved
import { useIsVideoBuffering } from '@nbcnews/omega-player/context/state/isBuffering';

/**
 * OmegaVideoLoadingAnimation: Shows a loading animation when a video is loading or buffering.
 *
 * @typedef {object} OmegaVideoLoadingAnimationProps
 * @property {boolean} isReady Flag to indicate if the media is ready to be played.
 *
 * @param {OmegaVideoLoadingAnimationProps} props
 *
 * @returns {JSX.Element|null} A JSX element representing the loading animation.
 */
export function VideoLoadingAnimation({ isReady }) {
  const [showLoader, setShowLoader] = useState(true);
  const isBuffering = useIsVideoBuffering();
  const isWorking = !isReady || isBuffering;
  useEffect(() => {
    setShowLoader(isWorking);
  }, [isWorking]);
  return (
    <>
      {
        showLoader ? (
          <div
            className="video-loading-animation"
            style={{
              placeSelf: 'center',
            }}
          >
            <Loader
              showLoader={isWorking}
              animateIn
              animateOut
              onAnimationEnd={() => { setShowLoader(false); }}
            />
          </div>
        )
          : null
      }
    </>
  );
}

VideoLoadingAnimation.propTypes = {
  isReady: PropTypes.bool.isRequired,
};

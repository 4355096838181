/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFeatureFlagContext } from './ContextTypes/featureFlag';

const CLIENT_FLAG_OVERRIDE_KEY = 'clientFlagOverride';

/**
 * @template {string} Name
 * @typedef {{
 *   [K in `if${Capitalize<Name>}`]: JSX.Element;
 * } & {
 *   children: JSX.Element;
 * }} SwitchComponentProps
 */

/**
 * @template {string} Name
 * @typedef {{ [K in `is${Capitalize<Name>}`]: boolean }} FlagProp
 */

/**
 * @template {string} T
 * @param {T} str
 * @returns {Capitalize<T>}
 */
function capitalize(str) {
  return /** @type {Capitalize<T>} */ (`${str.charAt(0).toUpperCase()}${str.slice(1)}`);
}

/**
 * Looks for flag overrides in the query string.  The query string value should be a
 * comma-separated string containing values prefixed by a '+' or '-' to indicate whether
 * the flag is enabled or disabled.  For example: `+gamma,-beta`
 *
 * @param {string} name the name of the flag
 * @returns {boolean | null} if null, then the setting isn't present
 */
function checkQueryStringOverride(name, value) {
  const match = new RegExp(`(?:^|,)([ +-])${name}(?:,|$)`).exec(value);
  return match && match[1] !== '-';
}

/**
 * @template T
 * @typedef {import('react').FunctionComponent<SwitchComponentProps<T>>} SwitchComponent
 */

/**
 * Creates a rendering hook + component for a feature flag.
 *
 * @example
 *
 * const { component: GammaFeatureFlagSwitch, hook: useIsGamma } = createFeatureFlagSwitch({
 *   name: 'gamma',
 *   key: 'use-gamma-vod',
 * });
 *
 * // Use hook within a render for conditional logic:
 * const isGamma = useIsGamma();
 *
 * // A component-based option is available as well:
 * <GammaFeatureFlagSwitch gamma={<div>This is the gamma content</div>}>
 *   <div>This is the control (i.e., default) content</div>
 * </GammaFeatureFlagSwitch>
 *
 * @template {string} Name
 *
 * @param {object} params
 * @param {Name} params.name name of the feature flag, used to generate the component name
 * @param {string} params.key name of the LaunchDarkly feature flag key
 * @returns {{
 *  component: SwitchComponent<Name>;
 *  hoc: <OriginalProps>(WrappedComponent: import('react').ComponentType<OriginalProps & FlagProp<Name>>) => import('react').ComponentType<OriginalProps>;
 *  hook: () => boolean;
 * }}
 */
export function createFeatureFlagSwitch({ name, key }) {
  /**
   * @returns {boolean} whether the switch is enabled
   */
  const useFlagIsEnabled = () => {
    const flags = useFeatureFlagContext();
    const [override, setOverride] = useState(/** @type {null | boolean} */ (null));

    useEffect(() => {
      const overrideString = new URLSearchParams(window.location.search).get(CLIENT_FLAG_OVERRIDE_KEY) ?? '';
      setOverride(checkQueryStringOverride(name, overrideString));
    }, []);

    return override !== null ? override : flags[key];
  };

  /**
   * @param {SwitchComponentProps<Name>} props
   */
  function Component({ children, ...props }) {
    const result = useFlagIsEnabled() ? props[`if${capitalize(name)}`] : children;
    return result || null;
  }

  Component.propTypes = {
    children: PropTypes.node.isRequired,
    [`if${capitalize(name)}`]: PropTypes.node.isRequired,
  };

  Component.displayName = `${capitalize(name)}FeatureFlagSwitch`;

  /**
   * @template {object} OriginalProps
   *
   * Higher Order Component for injecting the feature flag into a component
   * @param {React.ComponentType<OriginalProps & FlagProp<Name>>} WrappedComponent
   * @returns {React.ComponentType<OriginalProps>} WrappedComponent
   */
  function withFlag(Wrapped) {
    function WithFlag(props) {
      const isEnabled = useFlagIsEnabled();
      const newProps = { ...props, [`is${capitalize(name)}`]: isEnabled };
      return <Wrapped {...newProps} />;
    }

    WithFlag.displayName = `with${capitalize(name)}Flag`;

    return WithFlag;
  }

  return {
    hook: useFlagIsEnabled,
    hoc: withFlag,
    component: Component,
  };
}

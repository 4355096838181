import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { children as ChildrenPropType } from 'lib/CustomPropTypes';

import styles from './styles.module.scss';

/**
 * @summary Component for creating stacked elements using a CSS Grid approach.
 * @description Works by defining a CSS Grid with one column and one row, and
 * putting all direct children elements into that first column and row. Can
 * leverage CSS `justify-self` and `align-self` properties to position children
 * within the one-cell grid. Can also leverage `z-index` properties to define
 * custom stacking order, otherwise CSS Grid falls back to source order
 * (elements that appear later in the markup are on top of earlier elements).
 * @example
 * <GridStack>
 *   <video />
 *   <h2 style={{
 *     // using inline styles for simplicity of example, you can use a className
 *     justifySelf: 'start',
 *     alignSelf: 'stretch',
 *     zIndex: 1, // title should sit over top of play button
 *   }}>
 *     Video Title
 *   </h2>
 *   <button style={{
 *     justifySelf: 'center',
 *     alignSelf: 'center',
 *   }}>
 *     ▶️
 *   </button>
 * </GridStack>
 *
 * @typedef {object} Props
 * @property {ReactElement} Props.children
 * @property {string} [Props.className]
 *
 * @param {Props} props
 * @satisfies {React.ForwardedRef<HTMLDivElement, Props>}
 */
const GridStack = forwardRef(function GridStack({ // eslint-disable-line prefer-arrow-callback
  children,
  tagName: Tag = 'div',
  className,
  ...restProps
}, ref) {
  return (
    <Tag
      className={classNames(styles.gridStackWrapper, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      ref={ref}
    >
      {children}
    </Tag>
  );
});

GridStack.propTypes = {
  children: ChildrenPropType,
  tagName: PropTypes.string,
  className: PropTypes.string,
};

GridStack.defaultProps = {
  children: undefined,
  tagName: 'div',
  className: undefined,
};

export { GridStack };

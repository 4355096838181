import { useEffect } from 'react';
import { detect } from 'detect-browser';

export const fetchWasPatched = Symbol('fetchWasPatched');

/**
 * Monkey-patch fetch so that we can set the platform
 * value to `safari` for the ads request for the iOS app
 * and Chrome & Firefox on iOS. CVSDK incorrectly sets it as `web`.
 * @see https://nbcnewsdigital.atlassian.net/wiki/spaces/NGSP/pages/3683385427/iOS+App+issue+with+mismatched+ad+content+stream+formats
 */
export function useMonkeyPatchFetch() {
  useEffect(() => {
    try {
      if (!window[fetchWasPatched]) {
        window[fetchWasPatched] = true;

        const browser = detect();
        const { fetch: originalFetch } = window;

        window.fetch = async (...args) => {
          // eslint-disable-next-line prefer-const
          let [resource, config] = args;

          if (String(resource).includes('freewheel-params')) {
            const newResource = new URL(String(resource));
            const { searchParams } = newResource;
            const platform = searchParams.get('platform');

            if (
              ['crios', 'fxios', 'ios-webview'].includes(browser?.name || '')
              && platform !== 'safari'
            ) {
              searchParams.set('platform', 'safari');
              resource = newResource;
            }
          }

          return originalFetch(resource, config);
        };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error monkey-patching fetch', error);
    }
  }, []);
}

import { useInactivityMonitorUpdate } from 'lib/Hooks/useInactivityMonitorUpdate';
// eslint-disable-next-line import/no-unresolved
import { useIsVideoPaused } from '@nbcnews/omega-player/context/state/isPaused';
// eslint-disable-next-line import/no-unresolved
import { useIsUIVisible } from '@nbcnews/omega-player/context/state/isUIVisible';

/**
 * @param {object} props
 * @param {boolean} props.isStickyEnabled
 */
export function InactivityMonitorUpdate({ isStickyEnabled }) {
  const isPaused = useIsVideoPaused();
  const isVisible = useIsUIVisible();

  useInactivityMonitorUpdate(!isPaused && (isVisible || isStickyEnabled));

  return null;
}

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/**
 * ErrorSlate is a functional component that displays an error message slate.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.slateType - The type of slate to be displayed.
 * @param {string} props.heading - The main message to be displayed on the slate.
 * @param {string} props.details - The detailed message to be displayed on the slate.
 *
 * @returns {JSX.Element} The ErrorSlate component.
 */
const ErrorSlate = ({ slateType, heading, details }) => (
  <div className={`${styles[slateType]} ${styles.slate}`}>
    <p className={styles.mainMessage}>{heading}</p>
    <p className={styles.detailedMessage}>{details}</p>
  </div>
);

ErrorSlate.propTypes = {
  slateType: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
};

export { ErrorSlate };

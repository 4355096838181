import dynamic from 'next/dynamic';

/* eslint-disable import/no-unresolved */
export const VideoContextProvider = dynamic(
  () => import('@nbcnews/omega-player/VideoContextProvider').then((mod) => mod.VideoContextProvider),
  {
    ssr: false,
  },
);
export const Subtitles = dynamic(() => import('@nbcnews/omega-player/Subtitles'), {
  ssr: false,
});
export const TapToUnmuteButton = dynamic(() => import('@nbcnews/omega-player/TapToUnmuteButton'), {
  ssr: false,
});
export const CoreVideoPlayer = dynamic(() => import('@nbcnews/omega-player/CoreVideoPlayer'), {
  ssr: false,
});
export const VideoControlRack = dynamic(() => import('@nbcnews/omega-player/VideoControlRack'), {
  ssr: false,
});

import { useGeoLocation } from 'lib/Hooks';
import { useIsGeoRestrictedEnabled } from './GeoRestrictionSwitch';

const allowedRegions = ['US', 'AS', 'GU', 'MP', 'PR', 'VI'];

/**
 * Returns true if the user is in a "restricted" region.  Restricted regions
 * may not have access to video content.
 */
export function useIsGeoRestricted() {
  const { geoLocation: region = 'US' } = useGeoLocation();
  const enabled = useIsGeoRestrictedEnabled();

  const isRegionAllowed = allowedRegions.includes(region);

  return enabled && !isRegionAllowed;
}

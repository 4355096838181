import throttle from 'lodash.throttle';

const MONITOR_RATE_MS = 1000;
const RESET_RATE_MS = 500;

let listeners = [];
let lastUserActivity = null;
let isMonitoring = false;
let intervalId = null;

function monitor() {
  const now = Date.now();
  listeners.forEach((listener) => {
    if (now > (lastUserActivity + listener.delay)) {
      listener.callback();
    }
  });
}

function resetTimer() {
  if (isMonitoring) {
    lastUserActivity = Date.now();
  }
}

const inhibitors = new Set();

export default {
  startMonitoring() {
    if (isMonitoring || !listeners.length || inhibitors.size) {
      return;
    }
    intervalId = setInterval(monitor, MONITOR_RATE_MS);
    isMonitoring = true;
    lastUserActivity = Date.now();
  },

  stopMonitoring() {
    clearInterval(intervalId);
    isMonitoring = false;
    intervalId = null;
  },

  /**
   * Add an inhibitor to prevent the monitor from triggering
   * @param {string} inhibitorId
   */
  addInhibitor(inhibitorId) {
    inhibitors.add(inhibitorId);
    this.stopMonitoring();
  },

  /**
   * Remove inhibitor.  If all inhibitors are removed, start monitoring again
   * @param {string} inhibitorId
   */
  removeInhibitor(inhibitorId) {
    inhibitors.delete(inhibitorId);

    if (!inhibitors.size) {
      this.resetTimer();
      this.startMonitoring();
    }
  },

  resetTimer: throttle(resetTimer, RESET_RATE_MS, { leading: true, trailing: true }),

  after(delay, callback) {
    listeners.push({ delay, callback });
  },

  removeListener(cb) {
    listeners = listeners.filter((listener) => listener.callback !== cb);
  },
};

import { getPrivacyCookie } from 'lib/videoParamsCookie';
import { detect } from 'detect-browser';

const browser = detect() || { name: '' };


/**
* Detects the user's browser and retrieves the privacy setting.
* Mobile web views are not currently giving reliable values for privacy,
* and need to be set to opted out (1YYN).
* See https://nbcnewsdigital.atlassian.net/browse/NGSP-177
* @function
* @returns {string} The user's privacy setting. Either " " or "1---".
*/
export const getUsPrivacy = () => {
// if browser is not detected, return default value https://github.com/nbcnews/omega-player/blob/main/src/components/CoreVideoPlayer/generateSessionConfig/browserConfig.js#L67
  const privacySettingIsUnreliable = ['ios-webview', 'android', 'chromium-webview', 'edge-ios'].includes(browser?.name);
  return privacySettingIsUnreliable ? '1YYN' : getPrivacyCookie() || '1---'; // Fallback is default from Ramen: https://github.com/nbcnews/nextjs-ramen/blob/develop/src/lib/videoParamsCookie.js#L67
};

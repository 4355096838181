/* eslint-disable no-plusplus */
import { useEffect, useRef } from 'react';
import InactivityMonitor from 'lib/InactivityMonitor';

let counter = 0;

/**
 * @param {boolean} isPaused
 * @returns {void}
 */
export function useInactivityMonitorUpdate(isActive) {
  const id = useRef(++counter).current;

  useEffect(() => {
    /*
    addInhibitor calls stopMonitoring() -> InactivityMonitor stops monitoring

    InactivityMonitor.addInhibitor essentially inhibits inactivity monitoring.
    You only inhibit monitoring when it is active. In other words, you start monitoring
    the inactivity (and potentially trigger refresh) again when it is not active

    */
    if (isActive) {
      InactivityMonitor.addInhibitor(id);
    }
    return () => InactivityMonitor.removeInhibitor(id);
  }, [isActive, id]);
}

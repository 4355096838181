import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DefaultLoader from './loaders/default';
import NewsLoader from './loaders/news';
import TodayLoader from './loaders/today';

const loaders = {
  news: NewsLoader,
  today: TodayLoader,
  default: DefaultLoader,
};

const animations = {
  ENTERING: 'enterAnimation',
  LOADING: 'loadAnimation',
  EXITING: 'exitAnimation',
};

export class Loader extends React.Component {
  static propTypes = {
    showLoader: PropTypes.bool,
    brand: PropTypes.string,
    animateIn: PropTypes.bool,
    animateOut: PropTypes.bool,
    className: PropTypes.string,
    fillColor: PropTypes.string,
    onAnimationEnd: PropTypes.func,
    styles: PropTypes.objectOf(PropTypes.string),
  };

  static defaultProps = {
    showLoader: true,
    brand: 'default',
    animateIn: false,
    animateOut: false,
    fillColor: 'white',
    onAnimationEnd: null,
    className: null,
    styles: null,
  };

  /**
   *
   * @param {object} props
   * @param {boolean} [props.showLoader]
   */
  constructor(props) {
    super(props);
    this.animationEnd = this.animationEnd.bind(this);
    const { animateIn } = this.props;
    this.state = {
      currentAnimation: animateIn ? animations.ENTERING : animations.LOADING,
      enterAnimation: false,
      loadAnimation: false,
      exitAnimation: false,
    };
  }

  componentDidMount() {
    const { currentAnimation } = this.state;
    if (currentAnimation === animations.ENTERING) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        enterAnimation: true,
      });
    } else if (currentAnimation === animations.LOADING) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        loadAnimation: true,
      });
    }
  }

  componentDidUpdate() {
    const { showLoader, animateOut, onAnimationEnd } = this.props;
    const { currentAnimation } = this.state;
    if (!showLoader
      && animateOut
      && currentAnimation !== animations.EXITING
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        exitAnimation: true,
        currentAnimation: animations.EXITING,
      });
    } else if (!showLoader && !animateOut && typeof onAnimationEnd === 'function') {
      onAnimationEnd();
    }
  }

  animationEnd(event) {
    const { onAnimationEnd } = this.props;
    if (event?.animationName) {
      const isAnimationIn = event.animationName.toString().toLowerCase().match(/in/g);
      const isAnimationOut = event.animationName.toString().toLowerCase().match(/out/g);

      if (isAnimationIn) {
        this.setState({
          loadAnimation: true,
          currentAnimation: animations.LOADING,
        });
      } else if (isAnimationOut && typeof onAnimationEnd === 'function') {
        onAnimationEnd();
      }
    }
  }

  render() {
    const {
      className,
      styles,
      brand,
      fillColor,
    } = this.props;

    const { loadAnimation, enterAnimation, exitAnimation } = this.state;

    const classNames = cx({
      animateIn: enterAnimation,
      isLoading: loadAnimation,
      animateOut: exitAnimation,
    }, className);

    const loader = loaders?.[brand] ?? loaders.default;

    return loader(classNames, styles, this.animationEnd, fillColor);
  }
}

export default Loader;

const uuid = require('uuid');

// Cookie Config
const cookieConfig = {
  cookieName: 'fw_vcid2',
  privacyCookieName: 'usprivacy',
  cookieValue: uuid.v4(),
  expiration: 30, // days
};

/**
 * Set New Cookie
 *
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

/**
 * Get Cookie by Name
 *
 * @param cname
 * @returns {string}
 */
function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

/**
 * Set Freewheel VCID2 param
 *
 * @returns {string|*}
 */
export const setFreeWheelWVideoParamCookie = () => {
  // Get Cookie if Exist
  const cookie = getCookie(cookieConfig.cookieName);

  // Return stored UUID
  if (cookie) {
    return cookie;
  }

  // Set Cookie with new generated UUID Random string
  setCookie(cookieConfig.cookieName, cookieConfig.cookieValue, cookieConfig.expiration);

  // Return UUID Random string
  return cookieConfig.cookieValue;
};

/**
 * Get Privacy Cookie
 * @returns {string}
 */
export const getPrivacyCookie = () => {
  const defaultValue = '1---';
  const cookie = getCookie(cookieConfig.privacyCookieName);
  return cookie || defaultValue;
};

export default setFreeWheelWVideoParamCookie;

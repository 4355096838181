import { cookieOptionDecider } from 'lib/cookies';

export const VIDEO_RESERVE_PIP_GLOBAL_EVENT = 'globalPIPReservation';
export const VIDEO_PLAYING_GLOBAL_EVENT = 'globalVideoPlaying';
export const VIDEO_PLAYING_EVENT_ATTENUATION = 'debounce';

/**
 * Tests video object for matching live video type.
 * @param {object} video
 */
export const isVideoLive = (video) => {
  const type = video?.videoType ?? false;
  if (type && typeof type === 'string') {
    // Account for 'Non Broadcast Live Video' and 'nonBroadcastLiveVideo'
    return type.toLowerCase().replace(/\s+/, '') === 'nonbroadcastlivevideo';
  }
  return false;
};

// Autoplay related stuff
export const AUTOPLAY_TEST_GROUPS = {
  AUTOPLAY_ANY: 'AUTOPLAY_ANY',
  AUTOPLAY_UNMUTED_ONLY: 'AUTOPLAY_UNMUTED_ONLY',
  AUTOPLAY_DISABLED: 'AUTOPLAY_DISABLED',
};

export const AUTOPLAY_TEST_AD_KVS = {
  [AUTOPLAY_TEST_GROUPS.AUTOPLAY_ANY]: 'a',
  [AUTOPLAY_TEST_GROUPS.AUTOPLAY_UNMUTED_ONLY]: 'b',
  [AUTOPLAY_TEST_GROUPS.AUTOPLAY_DISABLED]: 'c',
};

export function getAutoplayTestGroup({
  AUTOPLAY_TEST_ANY_PERCENTAGE,
  AUTOPLAY_TEST_UNMUTED_PERCENTAGE,
  AUTOPLAY_TEST_DISABLED_PERCENTAGE,
}) {
  const groups = [
    [AUTOPLAY_TEST_GROUPS.AUTOPLAY_ANY, AUTOPLAY_TEST_ANY_PERCENTAGE],
    [AUTOPLAY_TEST_GROUPS.AUTOPLAY_UNMUTED_ONLY, AUTOPLAY_TEST_UNMUTED_PERCENTAGE],
    [AUTOPLAY_TEST_GROUPS.AUTOPLAY_DISABLED, AUTOPLAY_TEST_DISABLED_PERCENTAGE],
  ];

  return cookieOptionDecider(groups);
}

/**
 * @summary Takes a dBFS amount (usually <= 0) and converts to gain (usually 0 - 1)
 * @description Typically for use in a volume slider, to convert dBFS values to gain values. Most
 * video players use a linear gain scale, but the human ear is more accustomed to "loudness" on a
 * logarithmic scale.
 * @see {@link https://en.wikipedia.org/wiki/DBFS}
 * @example
 * const gain = dBFSToGain(-20);
 * console.log(gain) // 0.1
 * @param {number} dbfs amount of db reduction to the signal
 * @returns {number} gain, between 0-1
 */
export function dBFSToGain(dbfs) {
  return 10 ** (dbfs / 20);
}

/**
 * @summary Takes a gain amount (usually 0 - 1) and converts to dBFS (usually <= 0)
 * @description Typically for use in a volume slider, to convert gain values to dBFS values. Most
 * video players use a linear gain scale, but the human ear is more accustomed to "loudness" on a
 * logarithmic scale.
 * @see {@link https://en.wikipedia.org/wiki/DBFS}
 * @example
 * const dBFS = gainToDBFS(0.3);
 * console.log(dBFS) // -10.457574905606752
 * @param {number} dbfs amount of db reduction to the signal
 * @returns {number} gain, between 0-1
 */
export function gainToDBFS(gain) {
  return 20 * Math.log10(gain);
}

/**
 * Checks if a video is coming from one of our partners
 * @param {array} roles video's source roles
 * @returns {boolean}
 */
export const isSourcePartner = (roles) => {
  if (!roles || !roles.length) return false;

  const sourcePartners = ['partner', 'wire'];
  return roles.some((role) => sourcePartners.includes(role?.toLowerCase()));
};
